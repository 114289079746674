<template>
  <div class="recharge-page withdraw">
    <headerBar :title="i18n.t('withdraw.title')" class="green" :showArrow="true" @back="back"></headerBar>

    <div class="recharge-top">
      <div class="label">{{i18n.t('withdraw.label1')}}($)</div>
      <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
      <div class="flex tabs">
        <div class="flex-1">
          <div>{{i18n.t('withdraw.label2')}}($)</div>
          <div class="count">{{parseFloat(applying).toFixed(2)}}</div>
        </div>
        <div class="flex-1">
          <div>{{i18n.t('withdraw.label3')}}($)</div>
          <div class="count">{{parseFloat(total).toFixed(2)}}</div>
        </div>
      </div>
    </div>

    <div class="recharge-form" v-if="parseInt(withdraw_bind_bank,10) === 0">
      <div class="label">{{i18n.t('withdraw.label4')}}</div>
      <div class="input flex-center-between">
        <input type="tel" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="form.number" :placeholder="i18n.t('withdraw.placeholder1')">
        <span class="adot" @click="setAll">{{i18n.t('withdraw.label5')}}</span>
      </div>

      <div class="label">{{i18n.t('withdraw.label6')}}</div>
      <div class="input ">
        <input type="text" :readonly="readonly" v-model="form.address" :placeholder="i18n.t('withdraw.placeholder2')">
      </div>

      <div class="label">{{i18n.t('withdraw.label7')}}</div>
      <div class="input ">
        <input type="password" v-model="form.password" :placeholder="i18n.t('withdraw.placeholder3')">
      </div>
    </div>


    <div class="recharge-form" v-if="parseInt(withdraw_bind_bank,10) === 1">
      <div class="label">{{i18n.t('withdraw.label4')}}</div>
      <div class="input flex-center-between">
        <input type="tel" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" v-model="form.number" :placeholder="i18n.t('withdraw.placeholder1')">
        <span class="adot" @click="setAll">{{i18n.t('withdraw.label5')}}</span>
      </div>
      <div class="label">{{i18n.t('withdraw.label8')}}</div>
      <div class="input labelSelect" style="height: 1rem ;line-height: 1rem;width: 100%">
        <select v-model="form.type" style="height: 100%;width: 100%">
          <option value="">{{i18n.t('withdraw.placeholder6')}}</option>
          <option value="bank">{{i18n.t('withdraw.placeholder4')}}</option>
          <option value="usdt">{{i18n.t('withdraw.placeholder5')}}</option>
        </select>
      </div>
      <template v-if="form.type === 'usdt'">
        <template v-if="show_collection === 0">
          <div class="label">{{i18n.t('withdraw.label6')}}</div>
          <div class="input ">
            <input type="text" :readonly="true" v-model="form.address" :placeholder="i18n.t('withdraw.placeholder2')">
          </div>
        </template>
        <template v-else>
          <div class="label">{{i18n.t('withdraw.label10')}}</div>
          <div class="input ">
            <input type="text" :readonly="true" v-model="form.address" :placeholder="i18n.t('withdraw.placeholder7')">
          </div>
        </template>

      </template>
      <template v-if="form.type === 'bank'">
        <div class="label">{{i18n.t('withdraw.label9')}}</div>
        <div class="input ">
          <input type="text" :readonly="true" v-model="form.bank" :placeholder="i18n.t('withdraw.placeholder2')">
        </div>
      </template>

      <div class="label">{{i18n.t('withdraw.label7')}}</div>
      <div class="input ">
        <input type="password" v-model="form.password" :placeholder="i18n.t('withdraw.placeholder3')">
      </div>
    </div>


    <div class="btn">
      <Button @click="submit">{{i18n.t('withdraw.btn')}}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Form } from 'vant'
import headerBar from '@/components/header'
import {doWithdraw, getBankList, getUsdtList, userInfo, WithdrawalStatistics} from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },

  data() {
    return {
      form: {
        number: '',
        type: '',
        address: '',
        bank: '',
        password: '',
      },
      allow :false,
      readonly :false,
      bankShow:false,
      usdtShow:false,
      BankList: [],
      UsdtList: [],
      // 正在申请的提现余额
      applying: 0.00,
      // 累计提现余额
      total: 0.00,
      userMoney: 0,
    }
  },
    computed: {
        withdraw_bind_address() {
            return this.$store.state.withdraw_bind_address
        },
      withdraw_bind_bank() {
        return this.$store.state.withdraw_bind_bank
      },
      show_collection() {
        return this.$store.state.show_collection
      },
    },
  watch: {
    form: {
      handler(val) {
        console.log(val)
        if(val.type === "bank") {
          // 检查银行卡数量
          if(this.BankList.length === 0) {
            this.$toast({
              message: this.i18n.t('withdraw.bind_bank'),
              icon: 'warning-o',
            })
            setTimeout(() => {
              this.$router.push({
                name: "bindCard"
              })
            }, 1000)
          }
        }
        if(val.type === "usdt") {
          // 检查银行卡数量
          if(this.UsdtList.length === 0) {
            if(this.show_collection === 0) {
              this.$toast({
                message: this.i18n.t('withdraw.bind_address'),
                icon: 'warning-o',
              })
            } else {
              this.$toast({
                message: this.i18n.t('withdraw.bind_usdt_address'),
                icon: 'warning-o',
              })
            }

            setTimeout(() => {
              this.$router.push({
                name: "bind"
              })
            }, 1000)
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    init() {
      // 确定是否需要绑定地址才可以提现
      getUsdtList().then(res =>{
        this.usdtShow = true
        if (res.code === 0) {
          this.UsdtList = res.data.list
          if(res.data.list.length === 1) {
            this.form.address = res.data.list[0].address
          }
        }
      })
      getBankList().then(res =>{
        console.log(res.data)
        this.bankShow = true
        if (res.code === 0) {
          this.BankList = res.data.Items
          if(res.data.Items.length === 1) {
            this.form.bank = res.data.Items[0].RealName + this.$i18n.t("bind.Company") + " : " + res.data.Items[0].Company + this.$i18n.t("bind.Card") +  " ：" + res.data.Items[0].Card
            if(res.data.Items[0].TaxID.length > 0) {
              this.form.bank += this.$i18n.t("bind.TaxID") +" ：" + res.data.Items[0].TaxID
            }
          }
        }
      })
      // 确定是否需要绑定地址才可以提现
      if(this.withdraw_bind_address == 1) {
          getUsdtList().then(res =>{
              if (res.code === 0) {
                  console.log(res.data.list)
                  if(res.data.list.length === 1) {
                      this.allow = true
                      this.form.address = res.data.list[0].address
                      this.readonly = true
                  } else {
                      this.readonly = true
                      this.allow = false
                      this.$toast({
                          message: this.i18n.t('withdraw.bind_address'),
                          icon: 'warning-o',
                      })
                      setTimeout(() => {
                          this.$router.push({
                              name: "bind"
                          })
                      }, 1000)
                  }
              }
          })
      } else {
          this.allow = true
          this.readonly = false
      }
      WithdrawalStatistics().then(res => {
        if (res.code === 0) {
          this.applying = res.data.NowPrice
          this.total = res.data.TotalPrice
        }
      })
      userInfo().then(res => {
        this.userMoney = res.data.UserAccount.Withdraw
      })
    },
    handleInput() {
      // 正则表达式匹配数字
      let regex = /\d+/g;
      let matches = this.form.number.match(regex); // ["123", "456"]
      console.log("matches")
      console.log(matches)
      this.form.number = matches[0]
    },
    back() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.form.number) {
        this.$toast({
          message: this.i18n.t('withdraw.placeholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('withdraw.placeholder3'),
          icon: 'warning-o'
        })
        return
      }
      let form = new FormData()
      form.append('Price', this.form.number)
      form.append('Address', this.form.address)
      form.append('Passwd', this.form.password)
      form.append('Type', this.form.type)
      form.append('Bank', this.form.bank)
      if(parseInt(this.withdraw_bind_bank,10) === 1) {
        if(this.form.type === "") {
          this.$toast({
            message: this.i18n.t('withdraw.placeholder6'),
            icon: 'warning-o'
          })
          return
        }
      }
      doWithdraw(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('withdraw.success'))
        } else {
          this.$toast(res.msg)
        }
      })
    },
    setAll() {
      this.form.number = parseInt(this.userMoney,10)
    }
  }
}
</script>